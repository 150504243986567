@mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
}


