@import "_global";
@import "_reset";
@import "mixins";
@import "vars";

@mixin font($font_name, $file_name, $weight, $style) {
  @font-face {
    font-family: $font_name;
    font-display: swap;
    src: url("../fonts/#{$file_name}.ttf") format("ttf"),
      url("../fonts/#{$file_name}.ttf") format("ttf");
    font-weight: #{$weight};
    font-style: #{$style};
  }
}

@import "fonts";

// ================================= Header ===================================

.header { 
  .menu-burger {
    display: none;
  }
  width: 100%;
  padding-bottom: 50px;
  background: url(../img/header/header_ball.png) 76px 98px no-repeat, 
  url(../img/header/barrel_violet.png) 224px 50px no-repeat, 
  url(../img/header/header_half_ball.png) right 213px top 0 no-repeat;
  
  &__icon {  
    @include flex-center;
    margin: 0 auto;
    padding: 100px 0 30px;
  }
  &__menu { 
    &-list {  
      @include flex-center;
      column-gap: 16px;
    }
    &-item {  
      cursor: pointer;
      border-radius: 8px;
      background: #222222;
      padding: 10px 20px;
      &:hover a { 
        color: #FFFFFF;
      }
    }
    &-link {  
      font-size: 16px;
      line-height: 20px;
      color: #9E9E9E;
    }
  }
  
}

// ======================== We Develop the Future ============================

.tagline {  
  background: url(../img/header/3d_cube.png) left 142px bottom 0 no-repeat, 
  url(../img/header/3d_circle.png) right 104px bottom 70px no-repeat;
  &__title {  
  padding-top: 20px;
  font-size: 80px;
  line-height: 80px;
  text-align: center;
  color: #FFFFFF;
    span {  
      color: #00BE82;
    }
  }
  &__text { 
    margin: 0 auto;
    text-align: center;
    max-width: 354px;
    width: 100%;
    padding-top: 40px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #CACACA;
  }
  &__button { 
    text-align: center;
    padding: 64px 0 84px;
    &--products { 
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      background: #00BE82;
      border: none;
      border-radius: 12px;
      padding: 20px 56px;
      cursor: pointer;
    }
  }
}

// =============================== Cards ==================================

.cards {  
  background: url(../img/cards/cards_pic_ball.png) left 100px bottom 0 no-repeat, 
  url(../img/cards/cards_pic_half-ball.png) right 0 bottom 330px no-repeat;
  padding: 50px 0 60px;
  &__item { 
    margin: 0 auto;
    max-width: 720px;
    min-height: 300px;
    display: flex;
    border-radius: 20px;
    background: $gradient-cards;
    box-shadow: $shadow-cards;
    margin-bottom: 28px;
      &:nth-child(odd) {  
        flex-direction: row-reverse;
      }
  }

  &__pic {  
    margin: 0 auto;
    align-self: center;
    padding: 0 65px 0 30px; 
  }

  &__text { 
    margin: 0 auto;
    padding: 84px 0 84px 80px;
    &-mode { 
      padding-right: 50px;
      align-self: center;
    }
  }
  &__title { 
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    padding-bottom: 16px;
  }
  &__info { 
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    max-width: 303px;
  }
}


// ================================ uScan ===============================


.uscan {  
  background: url(../img/uscan/uscan_background_barrel.png) right 112px top 460px no-repeat, 
  url(../img/uscan/uscan_background_half-ball.png) left 0 top 520px no-repeat, 
  url(../img/uscan/uscan_background_element.png) right 0 bottom 320px no-repeat, 
  url(../img/uscan/uscan_background_ball.png) left 102px bottom 260px no-repeat, 
  url(../img/uscan/uscan_background_3d-cube.png) right 167px bottom 30px no-repeat;
  padding: 24px 0 40px;
  &__header { 
    @include center;
    gap: 25px;
    padding-bottom: 32px;
    &-icon {  
      background: #222222;
      border-radius: 8px;
      img {  
        margin: 18px 17px;
      }
    }
    &-title { 
      font-size: 64px;
      line-height: 64px;
      color: #FFFFFF;
    }
  }
  &__info { 
    margin: 0 auto;
    width: 100%;
    max-width: 860px;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 62px;
  }
  &__options {  
    @include flex-center;
    gap: 48px;
    padding-bottom: 60px;
    &-item { 
      max-width: 139px;
      text-align: center;
    }
    &-icon {  
      margin: 0 auto;
    }
    &-text {  
      font-size: 16px;
      line-height: 22px;
      padding-top: 1em;
      color: #FFFFFF;
    }
  }
  &__appstore-btn { 
    margin: 0 auto;
    @include flex-center;
    padding-bottom: 84px;
  }

  &__devices-images {  
    margin: 0 auto;
    @include flex-center;
  }
}


// ============================ Forms Contact Us ==========================


.contact { 
  position: relative; 
  &__title {  
    margin: 0 auto;
    width: 100%;
    max-width: 636px;
    font-size: 40px;
    line-height: 48px;
    color: #FFFFFF;
    text-align: center;
    padding-top: 48px;
    span {  
      color: #00BE82;
    }
  }
  &__form { 
    margin: 0 auto;
    width: 100%;
    max-width: 720px;
    margin-top: 48px;
  }
  &__forms {  
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
  &__item { 
    textarea.contact__input { 
      resize: none;
      max-width: 720px;
      width: 100%;
      min-height: 210px;
      padding: 14px 16px 0 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #939393;
    }
  }
  &__label {  
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #939393;
    span { 
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #d15d5d;
    }
  }
  &__input {  
    outline: none;
    height: 48px;
    width: 100%;
    max-width: 351px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #939393;
    border-color: transparent;
    background: #222222;
    padding: 0 16px;
    margin-top: 4px;
      &:focus { 
        box-shadow: 0 0 10px #272727;
      }
    transition: all 0.5 ease 0s;
      &::-webkit-input-placeholder { 
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #939393;
      }  
  }
  &__btn {  
    text-align: center;
    padding: 28px 0 190px;
    &-button { 
      cursor: pointer;
      border: 1px solid #00BE82;
      font-size: 14px;
      line-height: 24px;
      border-radius: 4px;
      background: #121212;
      padding: 10px 32px;
      color: #00BE82;
      &:hover { 
        background: #00BE82;
        color: #FFFFFF;
      }
    }
  }
}


// ========================== Footer ========================


.footer { 
  padding-top: 28px;
  background-color: #222222;
  &__inner {  
    display: flex;
    justify-content: space-between;
  }
  &__info { 
    &-rights {  
      font-size: 16px;
      line-height: 24px;  
      color: #FFFFFF;
    }
    &-location { 
      width: 100%;
      max-width: 351px;
      padding: 16px 0 52px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #B0B0B0;
    }
  }
  &__links {
    display: flex;
    font-size: 16px;
    line-height: 24px; 
    column-gap: 1em;
    &-item { 
      color: #FFFFFF;
  }
}
}

.send { 
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $back-message;
  top: 0;
  left: 0;
  visibility: hidden;

  &__body { 
    min-height: 100%;
    @include center;
    flex-direction: column;
    text-align: center;
    
  }

  &__content {  
    max-width: 300px;
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 30px 30px;
  }
  
  &__checkbox { 
   margin: 0 auto;
  }

  &__slogan { 
    font-size: 30px;
    padding: 16px 0;
    color: #575555;
  }

  &__text {  
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 25px;
    color: #575555;
  }

  &__btn { 
    padding: 10px 40px;
    color: #FFFFFF;
    background: #00BE82;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }
}

  .send.active {  
    visibility: visible;
  }

  @media only screen and (max-width: 1024px) {   
    .menu-burger {
      display: none;
    }
      .header { 
        width: 100%;
        padding-bottom: 30px;
        background: url(../img/header/barrel_ball_group_pic.png) left 32px top 44px no-repeat, 
        url(../img/header/header_half_ball_tablet.png) right 134px top 0 no-repeat;
        
          &__icon {  
            padding: 55px 0 24px;
          }
  
          &__menu { 
            &-item {  
              border-radius: 8px;
              padding: 10px 18px;
            }
            &-link {  
              font-size: 14px;
              line-height: 20px;
            }
          }
          
      }
  
  // =========================== Header Tagline ==========================
  
      .tagline {  
          background: url(../img/header/3d_cube_tablet.png) left 86px bottom 100px no-repeat, 
          url(../img/header/3d_circle_tablet.png) right 48px top 60px no-repeat;
          &__title {  
          padding-top: 10px;
          font-size: 44px;
          line-height: 46px;
          }
          &__text { 
            max-width: 263px;
            padding-top: 32px;
            font-size: 16px;
            line-height: 24px;
          }
          &__button { 
            padding: 44px 0 80px;
            &--products { 
              font-size: 16px;
              line-height: 24px;
              padding: 20px 50px;
            }
          }
      }
  
  // ========================= Cards ====================================
  
      .cards {  
          background: unset;
          padding: 20px 0 20px;
          &__item { 
            max-width: 612px;
            min-height: 250px;
            margin-bottom: 16px;
          }
        
          &__pic {  
            padding: 0 40px 0 30px;
           
          }
        
          &__text { 
            padding: 60px 0 60px 60px;
            &-mode { 
              padding-right: 50px;
            }
          }
        }
  
  // ======================== uScan ===================================
  
        .uscan {  
          background: url(../img/uscan/uscan_half_ball_tablet.png) right 0 top 0 no-repeat, 
          url(../img/uscan/uscan_ball_tablet.png) left 79px center no-repeat, 
          url(../img/uscan/uscan_background_barrel_tablet.png) right 40px bottom 190px no-repeat, 
          url(../img/uscan/uscan_black_half_ball_tablet.png) left 0 bottom 0 no-repeat;
          padding: 80px 0 80px;
          &__header { 
            gap: 20px;
            padding-bottom: 22px;
            &-icon {  
              img {  
                  margin: 15px 17px;
              }
            }
            &-title { 
              font-size: 32px;
              line-height: 40px;
            }
          }
          &__info { 
            max-width: 510px;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 52px;
          }
          &__options {  
            gap: 19px;
            padding-bottom: 52px;
            &-text {  
              font-size: 14px;
              line-height: 22px;
            }
          }
          &__appstore-btn { 
            padding-bottom: 100px;
          }
  
        }
  
  // ============================= Forms Contact Us =================================
  
        .contact {  
          background: url(../img/contact/contact_element_tablet.png) right 0 top 0 no-repeat, 
          url(../img/contact/contact_ball_tablet.png) left 63px bottom 110px no-repeat, 
          url(../img/contact/contact_3D_cube_tablet.png) right 88px bottom 85px no-repeat;
          &__title {  
            max-width: 415px;
            font-size: 28px;
            line-height: 32px;
            padding-top: 20px;
          }
          &__form { 
            max-width: 612px;
            margin-top: 52px;
          }
          &__item { 
            textarea.contact__input { 
              max-width: 612px;
            }
          }
          &__input {  
            max-width: 296px;
          }
          &__btn {  
            padding: 28px 0 166px;
          }
        }
        
  
  // ========================== Footer ========================
  
  
        .footer { 
          padding-top: 38px;
          
          &__inner {  
            flex-direction: column;
            padding-left: 101px;
          }
          &__info { 
            &-location { 
              max-width: 336px;
              padding: 16px 0 32px;
            }
          }
          &__links {
            padding-bottom: 64px;
            flex-direction: column;
            row-gap: 8px;
          }
        }
    
  }
  
  @media only screen and (max-width: 767px) { 
    
      
    .header { 
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      position: fixed;
      z-index: 200;
      width: 100%;
      border-bottom: 1px solid #9E9E9E;
      padding-bottom: unset;
      background: unset;
      background-color: #121212;
        &__icon {  
          display: flex;
          top: 50%;
          justify-content: unset;
          padding: 10px 0 10px 21px;
          margin: 0;
        }
        .menu-burger {
          display: flex;
          justify-content: flex-end;
          position: absolute;
          right: 2%;
          top: 20%;
          &__btn {
            width: 36px;
            height: 36px;       
            border: none;
            outline: none;
            border-radius: 50%;
            background: #222222;
          }
          &__line {
            display: block;
            margin: 0 auto;
            width: 16px;
            height: 2px;
            background-color: #FFFFFF;
            position: relative;
            &::before,
            &::after {
              content: "";
              width: 100%;
              height: 2px;
              position: absolute;
              // display: block;
              background-color: #FFFFFF;
              transition-duration: 500ms;
              left: 0;
            }
            &::before {
              transition-duration: 500ms;
              top: 6px;
            }
            &::after {
              transition-duration: 500ms;
              bottom: 6px;
            }
          }
          &__line--anim {
            // display: block;
            // margin: 0 auto;
            // width: 16px;
            // height: 2px;
            // background-color: #FFFFFF;
            // position: relative;
            background:transparent;
            &::before,
            &::after {
              position: absolute;
              // display: block;
              content: "";
              // width: 100%;
              height: 2px;
              background-color: #FFFFFF;
              left: 0;
            }
            &::before {
              transition-duration: 500ms;
              top: 0;
              transform: rotate(-45deg);
            }
            &::after {
              transition-duration: 500ms;
              bottom: 0;
              transform: rotate(45deg);
            }
          }
        }
       &__menu {  
        opacity: 0;
        z-index: 2;
        transition-duration: 500ms;
        &__active {
        transition-duration: 500ms;
        opacity: 1;
        }
        &-list {  
          position: absolute;
          right: 0;
          top: 62px;
          flex-direction: column;
          column-gap: unset;
        }
        &-item {  
          border-bottom: 2px solid #121212;
          border-radius: unset;
          padding: 20px 120px;
          &:last-child {  
            border-bottom: none;
          }
        }
        &-link {  
          font-size: 14px;
          line-height: 20px;
        }
       }
        
    }
  
  // =========================== Header Tagline ==========================
  
    .tagline {  
        background: unset;
        &__title {  
        padding-top: 113px;
        font-size: 32px;
        line-height: 40px;
        }
        &__text { 
          max-width: 263px;
          padding-top: 16px;
          font-size: 16px;
          line-height: 24px;
        }
        &__button { 
          padding: 32px 0 52px;
        }
    }
  
  // ========================= Cards ====================================
  
    .cards {  
        background: unset;
        padding: 20px 0 20px;
        &__item { 
          margin: 0 auto;
          max-width: 612px;
          min-height: 306px;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 28px;
            &:nth-child(odd) {  
              flex-direction: row-reverse;
            }
        }
      
        &__pic {  
          padding: 0 40px 0 30px;
          source {  
            width: 100%;
            height: auto;  
           }
        }
      
        &__text { 
          padding: unset;
          padding-left: 50px;
          align-self: center;
        }
      }
  
  // ======================== uScan ===================================
  
      .uscan {  
        background: unset;
        padding: 67px 0 32px;
        &__header { 
          flex-direction: column;
          gap: 16px;
          padding-bottom: 32px;
          &-icon {  
            img {  
              margin: 13px 15px;
            }
          }
          &-title { 
            font-size: 28px;
            line-height: 32px;
          }
        }
        &__info { 
          max-width: 343px;
          font-size: 18px;
          line-height: 24px;
          padding-bottom: 44px;
        }
        &__options {  
          flex-wrap: wrap;
          gap: 41px;
          padding-bottom: 45px;
        }
        &__appstore-btn { 
          padding-bottom: 44px;
        }
      }
  
  // ============================= Forms Contact Us =================================
  
      .contact {  
        background: unset;
        &__title {  
          max-width: 291px;
          font-size: 26px;
          line-height: 30px;
          padding-top: 60px;
        }
        &__form { 
          max-width: 612px;
          margin-top: 52px;
        }
        &__forms {  
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-bottom: 20px;
        }
        &__label {  
          display: block;
        }
        &__item { 
          textarea.contact__input { 
            max-width: 612px;
          }
        }
        &__input {  
          max-width: 100%;
        }
        &__btn {  
          padding: 44px 0 90px;
        }
      }
      
  
  // ========================== Footer ========================
  
  
      .footer { 
        
        &__inner {  
          flex-direction: column;
          padding-left: unset;
        }
        &__info { 
          &-location { 
            max-width: 336px;
            padding: 16px 0 32px;
          }
        }
        &__links {
          padding-bottom: 64px;
        }
      }
  
  }
  
  @media only screen and (max-width: 587px) {
    .cards {  
      padding: 20px 0 10px;
      &__item { 
        flex-direction: column;
        max-width: 612px;
        min-height: 306px;
        border-radius: 20px;
        background: linear-gradient(180deg, #D8D0F7 0%, #8EA8EC 100%);
        box-shadow: 1px -2px 10px rgba(130, 116, 186, 0.4);
        margin-bottom: 16px;
          &:nth-child(odd) {  
            flex-direction: column;
          }
      }
    
      &__pic {  
        padding: 32px 0 28px; 
      }
      &__title { 
        color: #000000;
        padding-bottom: 16px;
        text-align: center;
      }
      &__text { 
        padding: 8px 0 32px;
        text-align: center;
        &-mode { 
          padding-right: unset;
          padding-bottom: 32px;
          align-self: center;
          text-align: center;
        }
      }
    }
  }
  
  
  
  
  
  
