html, body {
  background-color: #121212;
  height: 100%;
  font-family: DM Sans, sans-serif;
  font-weight: 700;
}

.container {
  max-width: 940px;
  margin: 0 auto;
  padding: 0 16px;
}

html {
  box-sizing: border-box;
}

*, :after, :before {
  box-sizing: inherit;
}

ul[class], ol[class] {
  padding: 0;
}

body, h1, h2, h3, h4, h5, h6, p, ul, ol, li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

ul[class] {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: DM Sans;
  font-display: swap;
  src: url("DMSans-Bold.2b056bd1.ttf") format("ttf"), url("DMSans-Bold.2b056bd1.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: DM Sans;
  font-display: swap;
  src: url("DMSans-Medium.3ab6ec4e.ttf") format("ttf"), url("DMSans-Medium.3ab6ec4e.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: DM Sans;
  font-display: swap;
  src: url("DMSans-Regular.3d6ed644.ttf") format("ttf"), url("DMSans-Regular.3d6ed644.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

.header {
  background: url("header_ball.93b6b738.png") 76px 98px no-repeat, url("barrel_violet.01ad8ff3.png") 224px 50px no-repeat, url("header_half_ball.70455568.png") right 213px top 0 no-repeat;
  width: 100%;
  padding-bottom: 50px;
}

.header .menu-burger {
  display: none;
}

.header__icon {
  justify-content: center;
  margin: 0 auto;
  padding: 100px 0 30px;
  display: flex;
}

.header__menu-list {
  justify-content: center;
  column-gap: 16px;
  display: flex;
}

.header__menu-item {
  cursor: pointer;
  background: #222;
  border-radius: 8px;
  padding: 10px 20px;
}

.header__menu-item:hover a {
  color: #fff;
}

.header__menu-link {
  color: #9e9e9e;
  font-size: 16px;
  line-height: 20px;
}

.tagline {
  background: url("3d_cube.732ae352.png") left 142px bottom 0 no-repeat, url("3d_circle.b43b8a0e.png") right 104px bottom 70px no-repeat;
}

.tagline__title {
  text-align: center;
  color: #fff;
  padding-top: 20px;
  font-size: 80px;
  line-height: 80px;
}

.tagline__title span {
  color: #00be82;
}

.tagline__text {
  text-align: center;
  color: #cacaca;
  width: 100%;
  max-width: 354px;
  margin: 0 auto;
  padding-top: 40px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.tagline__button {
  text-align: center;
  padding: 64px 0 84px;
}

.tagline__button--products {
  color: #fff;
  cursor: pointer;
  background: #00be82;
  border: none;
  border-radius: 12px;
  padding: 20px 56px;
  font-size: 16px;
  line-height: 24px;
}

.cards {
  background: url("cards_pic_ball.6c19fa59.png") left 100px bottom 0 no-repeat, url("cards_pic_half-ball.d78c740e.png") right 0 bottom 330px no-repeat;
  padding: 50px 0 60px;
}

.cards__item {
  background: linear-gradient(#d8d0f7 0%, #8ea8ec 100%);
  border-radius: 20px;
  max-width: 720px;
  min-height: 300px;
  margin: 0 auto 28px;
  display: flex;
  box-shadow: 1px -2px 10px #8274ba66;
}

.cards__item:nth-child(odd) {
  flex-direction: row-reverse;
}

.cards__pic {
  align-self: center;
  margin: 0 auto;
  padding: 0 65px 0 30px;
}

.cards__text {
  margin: 0 auto;
  padding: 84px 0 84px 80px;
}

.cards__text-mode {
  align-self: center;
  padding-right: 50px;
}

.cards__title {
  color: #000;
  padding-bottom: 16px;
  font-size: 20px;
  line-height: 28px;
}

.cards__info {
  color: #000;
  max-width: 303px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.uscan {
  background: url("uscan_background_barrel.c4ee9a6c.png") right 112px top 460px no-repeat, url("uscan_background_half-ball.5149eb2d.png") 0 520px no-repeat, url("uscan_background_element.2a18947b.png") right 0 bottom 320px no-repeat, url("uscan_background_ball.6954a57b.png") left 102px bottom 260px no-repeat, url("uscan_background_3d-cube.aa2da305.png") right 167px bottom 30px no-repeat;
  padding: 24px 0 40px;
}

.uscan__header {
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding-bottom: 32px;
  display: flex;
}

.uscan__header-icon {
  background: #222;
  border-radius: 8px;
}

.uscan__header-icon img {
  margin: 18px 17px;
}

.uscan__header-title {
  color: #fff;
  font-size: 64px;
  line-height: 64px;
}

.uscan__info {
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  padding-bottom: 62px;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
}

.uscan__options {
  justify-content: center;
  gap: 48px;
  padding-bottom: 60px;
  display: flex;
}

.uscan__options-item {
  text-align: center;
  max-width: 139px;
}

.uscan__options-icon {
  margin: 0 auto;
}

.uscan__options-text {
  color: #fff;
  padding-top: 1em;
  font-size: 16px;
  line-height: 22px;
}

.uscan__appstore-btn {
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 84px;
  display: flex;
}

.uscan__devices-images {
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.contact {
  position: relative;
}

.contact__title {
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 636px;
  margin: 0 auto;
  padding-top: 48px;
  font-size: 40px;
  line-height: 48px;
}

.contact__title span {
  color: #00be82;
}

.contact__form {
  width: 100%;
  max-width: 720px;
  margin: 48px auto 0;
}

.contact__forms {
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
  display: grid;
}

.contact__item textarea.contact__input {
  resize: none;
  color: #939393;
  width: 100%;
  max-width: 720px;
  min-height: 210px;
  padding: 14px 16px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.contact__label {
  color: #939393;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.contact__label span {
  color: #d15d5d;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.contact__input {
  color: #939393;
  transition: all .5 ease 0s;
  background: #222;
  border-color: #0000;
  border-radius: 8px;
  outline: none;
  width: 100%;
  max-width: 351px;
  height: 48px;
  margin-top: 4px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.contact__input:focus {
  box-shadow: 0 0 10px #272727;
}

.contact__input::-webkit-input-placeholder {
  color: #939393;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.contact__btn {
  text-align: center;
  padding: 28px 0 190px;
}

.contact__btn-button {
  cursor: pointer;
  color: #00be82;
  background: #121212;
  border: 1px solid #00be82;
  border-radius: 4px;
  padding: 10px 32px;
  font-size: 14px;
  line-height: 24px;
}

.contact__btn-button:hover {
  color: #fff;
  background: #00be82;
}

.footer {
  background-color: #222;
  padding-top: 28px;
}

.footer__inner {
  justify-content: space-between;
  display: flex;
}

.footer__info-rights {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}

.footer__info-location {
  color: #b0b0b0;
  width: 100%;
  max-width: 351px;
  padding: 16px 0 52px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.footer__links {
  column-gap: 1em;
  font-size: 16px;
  line-height: 24px;
  display: flex;
}

.footer__links-item {
  color: #fff;
}

.send {
  visibility: hidden;
  background-color: #0009;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.send__body {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.send__content {
  background-color: #fff;
  border-radius: 8px;
  max-width: 300px;
  padding: 30px;
}

.send__checkbox {
  margin: 0 auto;
}

.send__slogan {
  color: #575555;
  padding: 16px 0;
  font-size: 30px;
}

.send__text {
  color: #575555;
  padding-bottom: 25px;
  font-size: 16px;
  font-weight: 500;
}

.send__btn {
  color: #fff;
  cursor: pointer;
  background: #00be82;
  border: none;
  border-radius: 8px;
  padding: 10px 40px;
}

.send.active {
  visibility: visible;
}

@media only screen and (width <= 1024px) {
  .menu-burger {
    display: none;
  }

  .header {
    background: url("barrel_ball_group_pic.9b08867f.png") 32px 44px no-repeat, url("header_half_ball_tablet.85373360.png") right 134px top 0 no-repeat;
    width: 100%;
    padding-bottom: 30px;
  }

  .header__icon {
    padding: 55px 0 24px;
  }

  .header__menu-item {
    border-radius: 8px;
    padding: 10px 18px;
  }

  .header__menu-link {
    font-size: 14px;
    line-height: 20px;
  }

  .tagline {
    background: url("3d_cube_tablet.11ef62e8.png") left 86px bottom 100px no-repeat, url("3d_circle_tablet.b30c5d76.png") right 48px top 60px no-repeat;
  }

  .tagline__title {
    padding-top: 10px;
    font-size: 44px;
    line-height: 46px;
  }

  .tagline__text {
    max-width: 263px;
    padding-top: 32px;
    font-size: 16px;
    line-height: 24px;
  }

  .tagline__button {
    padding: 44px 0 80px;
  }

  .tagline__button--products {
    padding: 20px 50px;
    font-size: 16px;
    line-height: 24px;
  }

  .cards {
    background: unset;
    padding: 20px 0;
  }

  .cards__item {
    max-width: 612px;
    min-height: 250px;
    margin-bottom: 16px;
  }

  .cards__pic {
    padding: 0 40px 0 30px;
  }

  .cards__text {
    padding: 60px 0 60px 60px;
  }

  .cards__text-mode {
    padding-right: 50px;
  }

  .uscan {
    background: url("uscan_half_ball_tablet.0e879686.png") right 0 top 0 no-repeat, url("uscan_ball_tablet.8b6c1afd.png") 79px 50% no-repeat, url("uscan_background_barrel_tablet.a54447dd.png") right 40px bottom 190px no-repeat, url("uscan_black_half_ball_tablet.2e6b4391.png") left 0 bottom 0 no-repeat;
    padding: 80px 0;
  }

  .uscan__header {
    gap: 20px;
    padding-bottom: 22px;
  }

  .uscan__header-icon img {
    margin: 15px 17px;
  }

  .uscan__header-title {
    font-size: 32px;
    line-height: 40px;
  }

  .uscan__info {
    max-width: 510px;
    padding-bottom: 52px;
    font-size: 16px;
    line-height: 24px;
  }

  .uscan__options {
    gap: 19px;
    padding-bottom: 52px;
  }

  .uscan__options-text {
    font-size: 14px;
    line-height: 22px;
  }

  .uscan__appstore-btn {
    padding-bottom: 100px;
  }

  .contact {
    background: url("contact_element_tablet.fdeeb6bd.png") right 0 top 0 no-repeat, url("contact_ball_tablet.94594f7a.png") left 63px bottom 110px no-repeat, url("contact_3D_cube_tablet.5452d2bb.png") right 88px bottom 85px no-repeat;
  }

  .contact__title {
    max-width: 415px;
    padding-top: 20px;
    font-size: 28px;
    line-height: 32px;
  }

  .contact__form {
    max-width: 612px;
    margin-top: 52px;
  }

  .contact__item textarea.contact__input {
    max-width: 612px;
  }

  .contact__input {
    max-width: 296px;
  }

  .contact__btn {
    padding: 28px 0 166px;
  }

  .footer {
    padding-top: 38px;
  }

  .footer__inner {
    flex-direction: column;
    padding-left: 101px;
  }

  .footer__info-location {
    max-width: 336px;
    padding: 16px 0 32px;
  }

  .footer__links {
    flex-direction: column;
    row-gap: 8px;
    padding-bottom: 64px;
  }
}

@media only screen and (width <= 767px) {
  .header {
    z-index: 200;
    padding-bottom: unset;
    background: unset;
    background-color: #121212;
    border-bottom: 1px solid #9e9e9e;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    display: flex;
    position: fixed;
  }

  .header__icon {
    justify-content: unset;
    margin: 0;
    padding: 10px 0 10px 21px;
    display: flex;
    top: 50%;
  }

  .header .menu-burger {
    justify-content: flex-end;
    display: flex;
    position: absolute;
    top: 20%;
    right: 2%;
  }

  .header .menu-burger__btn {
    background: #222;
    border: none;
    border-radius: 50%;
    outline: none;
    width: 36px;
    height: 36px;
  }

  .header .menu-burger__line {
    background-color: #fff;
    width: 16px;
    height: 2px;
    margin: 0 auto;
    display: block;
    position: relative;
  }

  .header .menu-burger__line:before, .header .menu-burger__line:after {
    content: "";
    background-color: #fff;
    width: 100%;
    height: 2px;
    transition-duration: .5s;
    position: absolute;
    left: 0;
  }

  .header .menu-burger__line:before {
    transition-duration: .5s;
    top: 6px;
  }

  .header .menu-burger__line:after {
    transition-duration: .5s;
    bottom: 6px;
  }

  .header .menu-burger__line--anim {
    background: none;
  }

  .header .menu-burger__line--anim:before, .header .menu-burger__line--anim:after {
    content: "";
    background-color: #fff;
    height: 2px;
    position: absolute;
    left: 0;
  }

  .header .menu-burger__line--anim:before {
    transition-duration: .5s;
    top: 0;
    transform: rotate(-45deg);
  }

  .header .menu-burger__line--anim:after {
    transition-duration: .5s;
    bottom: 0;
    transform: rotate(45deg);
  }

  .header__menu {
    opacity: 0;
    z-index: 2;
    transition-duration: .5s;
  }

  .header__menu__active {
    opacity: 1;
    transition-duration: .5s;
  }

  .header__menu-list {
    column-gap: unset;
    flex-direction: column;
    position: absolute;
    top: 62px;
    right: 0;
  }

  .header__menu-item {
    border-radius: unset;
    border-bottom: 2px solid #121212;
    padding: 20px 120px;
  }

  .header__menu-item:last-child {
    border-bottom: none;
  }

  .header__menu-link {
    font-size: 14px;
    line-height: 20px;
  }

  .tagline {
    background: unset;
  }

  .tagline__title {
    padding-top: 113px;
    font-size: 32px;
    line-height: 40px;
  }

  .tagline__text {
    max-width: 263px;
    padding-top: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  .tagline__button {
    padding: 32px 0 52px;
  }

  .cards {
    background: unset;
    padding: 20px 0;
  }

  .cards__item {
    flex-wrap: wrap;
    max-width: 612px;
    min-height: 306px;
    margin: 0 auto 28px;
    display: flex;
  }

  .cards__item:nth-child(odd) {
    flex-direction: row-reverse;
  }

  .cards__pic {
    padding: 0 40px 0 30px;
  }

  .cards__pic source {
    width: 100%;
    height: auto;
  }

  .cards__text {
    padding: unset;
    align-self: center;
    padding-left: 50px;
  }

  .uscan {
    background: unset;
    padding: 67px 0 32px;
  }

  .uscan__header {
    flex-direction: column;
    gap: 16px;
    padding-bottom: 32px;
  }

  .uscan__header-icon img {
    margin: 13px 15px;
  }

  .uscan__header-title {
    font-size: 28px;
    line-height: 32px;
  }

  .uscan__info {
    max-width: 343px;
    padding-bottom: 44px;
    font-size: 18px;
    line-height: 24px;
  }

  .uscan__options {
    flex-wrap: wrap;
    gap: 41px;
    padding-bottom: 45px;
  }

  .uscan__appstore-btn {
    padding-bottom: 44px;
  }

  .contact {
    background: unset;
  }

  .contact__title {
    max-width: 291px;
    padding-top: 60px;
    font-size: 26px;
    line-height: 30px;
  }

  .contact__form {
    max-width: 612px;
    margin-top: 52px;
  }

  .contact__forms {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    display: flex;
  }

  .contact__label {
    display: block;
  }

  .contact__item textarea.contact__input {
    max-width: 612px;
  }

  .contact__input {
    max-width: 100%;
  }

  .contact__btn {
    padding: 44px 0 90px;
  }

  .footer__inner {
    padding-left: unset;
    flex-direction: column;
  }

  .footer__info-location {
    max-width: 336px;
    padding: 16px 0 32px;
  }

  .footer__links {
    padding-bottom: 64px;
  }
}

@media only screen and (width <= 587px) {
  .cards {
    padding: 20px 0 10px;
  }

  .cards__item {
    background: linear-gradient(#d8d0f7 0%, #8ea8ec 100%);
    border-radius: 20px;
    flex-direction: column;
    max-width: 612px;
    min-height: 306px;
    margin-bottom: 16px;
    box-shadow: 1px -2px 10px #8274ba66;
  }

  .cards__item:nth-child(odd) {
    flex-direction: column;
  }

  .cards__pic {
    padding: 32px 0 28px;
  }

  .cards__title {
    color: #000;
    text-align: center;
    padding-bottom: 16px;
  }

  .cards__text {
    text-align: center;
    padding: 8px 0 32px;
  }

  .cards__text-mode {
    padding-right: unset;
    text-align: center;
    align-self: center;
    padding-bottom: 32px;
  }
}

/*# sourceMappingURL=index.5429e42b.css.map */
