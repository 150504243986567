html,
body {
	height: 100%;
	font-family: 'DM Sans', sans-serif;
	font-weight: 700;
	background-color: #121212;
}


.container{
  max-width: 940px;
  margin: 0 auto;
  padding: 0 16px;
}





